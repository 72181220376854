import { IPAddress } from "../../app/models/ipAddress";
import { useEffect, useRef, RefObject} from "react";
import MaterialTable, { MTableAction } from "material-table";
import Iput from "iput";
import { Button, Input, Header } from "semantic-ui-react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import StyledHeader from "../../app/common/styled-element/StyledHeader";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { Paper, TablePagination } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";

const StyledContainer = styled.div`
  padding: 1em 2em;
`;

const useStyles = makeStyles({
    theme: {
        boxShadow: "none",
        '& [class*="Component-horizontalScrollContainer"]': {
            padding: "0 2px",
        },
        " &.MuiPaper-rounded": {
            borderRadius: "20px",
        },
        "& .MuiTablePagination-root": {
            borderBottom: "none",
        },
        "& .MuiTablePagination-toolbar": {
            minHeight: "60px",
        },
        "& .MuiTablePagination-toolbar .MuiButton-label": {
            fontSize: "1rem",
        },
        "& .MuiTablePagination-toolbar .MuiIconButton-label > span": {
            fontSize: "1.6rem",
        },
        "& .MuiToolbar-regular": {
            minHeight: "10px",
        },
        "& .MuiTableCell-root ": {
            borderBottom: "1px solid e0e0e0",
            padding: "13px",
        },
        "& .MuiTableCell-paddingNone ": {
            borderBottom: "1px solid white",
        },
        "& .MuiTableCell-paddingNone div": {
            padding: "0px 0px 0px 61px"
        },
        "& .MuiTableCell-paddingCheckbox:last-child": {
            paddingLeft: "35px"
        }
       
    },
    ipInput: {
        "& input[type='text']": {
            fontFamily: "Lato",
            fontSize: "1rem",
            padding: "4.5px",
            width: "2.7rem",
        },
    },
    disablePagination: {
        pointerEvents: "none",

        "& span.MuiButton-label": {
            color: "lightgray",
        },
        "& .MuiIconButton-root": {
            color: "lightgray",
        } 
       },
});

const IPAddressSettingsList = () => {
    const addActionRef: RefObject<HTMLDivElement> = useRef(null);
    const classes = useStyles();
    let isError: boolean = false;
    const { ipAddressStore } = useStore();
    const swal = withReactContent(Swal);
    const {
        getAllIPAddresses,
        isLoading,
        deleteIPAddress,
        updateIPAddress,
        createIPAddress,
        ipAddresses,
        setLoading,
        setDisabled,
        isDisabled
    } = ipAddressStore;

    useEffect(() => {
        getAllIPAddresses();
        setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDeleteIPAddress = async (rowData: any) => {
        swal.fire({
            title: "Delete Confirmation",
            text: "Are you sure you want to delete this IP Address?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                deleteIPAddress(rowData.id);
            }
        });
    };

    const paginationClassName = (isDisabled === true) && classes.disablePagination;

    const isIPAddressValid = (ipaddress: string) => {
        return (
            ipaddress.match(/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/) ??
            (toast.error("You have entered an invalid IP address!"), false)
        );
    };

    const isIPAddressDuplicated = (ipaddress: string) => {
        return ipAddresses.some((i) => i.address === ipaddress ) ? (toast.error("This IP address is already exists!")) : false;
    };

    return (
        <>
            <Button
                primary
                floated="right"
                style={{ marginRight: 28 }}
                onClick={() => {
                    addActionRef.current?.click();
                    setDisabled(true); }
                }
            >
                Add a new IP Address
            </Button>
            <StyledContainer>
                <StyledHeader content="IP Address Whitelist" />
                <MaterialTable
                    columns={[
                        {
                            title: "IP Addresses",
                            field: "address",
                            cellStyle: {
                                width: "90%",
                                borderBottom: "1px solid white",
                            },
                            editComponent: (props: any) => {
                                return (
                                    <Iput
                                        defaultValue={props.value}
                                        className={classes.ipInput}
                                        onChange={props.onChange}
                                        isError={() => isError}
                                    />
                                );
                            },
                            render: (rowData: IPAddress) => (
                                <Input
                                    value={rowData.address.split(".").join("  .  ")}
                                    readOnly
                                />
                            ),
                        },
                    ]}
                    data={ipAddresses}
                    editable={{
                        onRowAddCancelled: () => {
                            isError = false;
                            setDisabled(false)
                        },
                        onRowUpdateCancelled: () => {
                            isError = false;
                            setDisabled(false)
                        },
                        onRowAdd: (newData) =>
                            new Promise((resolve, reject) => {
                                if (isIPAddressValid(newData.address) && !isIPAddressDuplicated(newData.address)) {
                                    createIPAddress(newData);
                                    resolve(newData);
                                    setDisabled(false);
                                } else {
                                    isError = true;
                                    reject(new Error("Create of an IP Address failed"));
                                }
                            }),
                        onRowUpdate: (newData) =>
                            new Promise((resolve, reject) => {
                                if (isIPAddressValid(newData.address) && !isIPAddressDuplicated(newData.address)) {
                                    updateIPAddress(newData);
                                    resolve(newData);
                                } else {
                                    isError = true;
                                    reject(new Error("IP Address update failed"));
                                }
                            }),
                    }}
                    actions={[
                        {
                            icon: "delete",
                            tooltip: "Delete IP Address",
                            onClick: (_, rowData) => {
                                onDeleteIPAddress(rowData);
                            },
                        },
                    ]}
                    isLoading={isLoading}
                    style={{ padding: "0 5px" }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: (
                                <Header
                                    as="h4"
                                    style={{
                                        textAlign: "center",
                                        fontWeight: 600,
                                        padding: "1em",
                                    }}
                                >
                                    No Records
                                </Header>
                            ),
                        },
                    }}
                    options={{
                        addRowPosition: "first",
                        actionsCellStyle: {
                            direction: "rtl",
                            paddingRight: "36px",
                        },
                        paginationType: "stepped",
                        pageSize: 10,
                        loadingType: "linear",
                        actionsColumnIndex: -1,
                        search: false,
                        showTitle: false,
                        paging: true,
                        sorting: false,
                        draggable: false,
                        pageSizeOptions: [],
                        maxBodyHeight: 590,
                        toolbar: true,
                        headerStyle: {
                            fontWeight: "bold",
                            fontSize: "15px",
                        },
                        rowStyle: (_data: any, index: number, _level: number) => {
                            return index % 2 ? { backgroundColor: '#f3f9ff', padding: '0 7px' } : { padding: '0 7px' };
                        }
                    }}
                    components={{
                        Action: (props) => {
                            return props.action.tooltip === "Add" ? (
                                <div ref={addActionRef} onClick={props.action.onClick} />
                            ) : (
                                <MTableAction {...props} />
                            );
                        },
                        Pagination: props => <TablePagination {...props} className={paginationClassName} />,

                        Container: (props) => (
                            <Paper className={classes.theme} {...props} />
                        ),
                    }}
                />
            </StyledContainer>
        </>
    );
};

export default observer(IPAddressSettingsList);
