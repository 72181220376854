import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agents";
import { IPAddress } from "../models/ipAddress";
import { toast } from 'react-toastify';
export default class IPAddressStore {
    isLoading: boolean = false;
    ipAddresses: IPAddress[] = [];
    ipAddress: IPAddress | undefined = undefined;
    isDisabled: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    setLoading = (state: boolean) => {
        this.isLoading = state;
    }

    setDisabled = (state: boolean) => {
        this.isDisabled = state;
    }

    getAllIPAddresses = async () => {
        try {
            const data = await agent.IPAddresses.getAll();
            this.setLoading(false);
            return this.ipAddresses = data;;
        } catch (error) {
            console.error(error);
            this.setLoading(false);
        }
    }

    getIPAddress = async (id: string) => {
        this.isLoading = true;

        try {
            await agent.IPAddresses.get(id);
            runInAction(() => {
                this.isLoading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.isLoading = false;
            })
        }
    };

    createIPAddress = async (ipAddress: IPAddress) => {
        this.isLoading = true;

        try {
            let id = await agent.IPAddresses.create(ipAddress);

            runInAction(() => {
                this.ipAddresses = [...this.ipAddresses, {
                    id: id,
                    address: ipAddress.address
                }];
                toast.success("IP Address is successfully added.");
                this.isLoading = false;
                this.getAllIPAddresses();
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.isLoading = false;
            })
        }
    };

    updateIPAddress = async (ipAddress: IPAddress) => {
        this.isLoading = true;

        try {
            await agent.IPAddresses.update(ipAddress);

            runInAction(() => {
                this.ipAddresses = this.ipAddresses.map(i => {
                    if (i.id === ipAddress.id) {
                        return {
                            id: i.id, 
                            address: ipAddress.address
                        }
                    }
                    return i;
                });
                toast.success("IP Address is successfully updated.");
                this.isLoading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.isLoading = false;
            })
        }
    };

    deleteIPAddress = async (id: string) => {
        this.isLoading = true;

        try {
            await agent.IPAddresses.delete(id);
            runInAction(() => {
                this.isLoading = false;
                this.ipAddresses = this.ipAddresses.filter(i => i.id != id);
                toast.success("IP Address is successfully deleted.");
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.isLoading = false;
            })
        }
    };
}