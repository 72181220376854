import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Container } from 'semantic-ui-react';
import ForgotPasswordForm from '../../features/users/ForgotPasswordForm';
import LoginForm from '../../features/users/LoginForm';
import ResetPasswordForm from '../../features/users/ResetPasswordForm';
import ModalContainer from '../common/modal/ModalContainer';
import { useStore } from '../stores/store';
import LoadingComponent from './LoadingComponent';
import PrivateRoute from './PrivateRoute';
import ApplicationModuleDashboard from '../../features/application-module/ApplicationModuleDashboard'
import RoleDashboard from '../../features/roles/RoleDashboard'
import RoleForm from '../../features/roles/form/RoleForm'
import UserList from '../../features/roles/UserList';
import CompanyProfileForm from '../../features/company-profile/CompanyProfileForm';
import IPAddressSettingsList from '../../features/settings/IPAddressSettingsList';
import * as Permissions from '../constants/permissions';
import NotFound from '../../features/errors/NotFound';

function App() {
    const { commonStore, userStore, authStore } = useStore();
    const location = useLocation();

    useEffect(() => {
        if (authStore.token) {
            userStore.getUser().finally(() => {
                commonStore.setAppLoaded();
                authStore.startRefreshTokenTimer();
            });
        } else {
            commonStore.setAppLoaded();
        }
    }, [authStore, userStore]);

    if (!commonStore.appLoaded) return <LoadingComponent content='Loading Kasina...' />

    return (
        <>
            <ToastContainer position='top-right' hideProgressBar theme='colored' />
            <ModalContainer />
            <>
                <Switch>
                    <Route exact={true} path='/' component={() => <Redirect to="/applications" />} />
                    <PrivateRoute permission={Permissions.ApplicationAndModules} path='/applications' component={ApplicationModuleDashboard} />
                    <PrivateRoute permission={Permissions.Roles} exact={true} path='/roles' component={RoleDashboard} />
                    <PrivateRoute permission={Permissions.Roles} key={location.key} path={['/roles/create', '/roles/edit/:id']} component={RoleForm} />
                    <PrivateRoute permission={Permissions.UserRoles} exact={true} path='/users' component={UserList} />
                    <PrivateRoute permission={Permissions.CompanyProfile} path='/profiles' component={CompanyProfileForm} />
                    <PrivateRoute permission={Permissions.IpAddress} path='/settings/ip-addresses' component={IPAddressSettingsList} />
                    <Route component={DefaultLayout} />
                </Switch>
            </>
        </>
    );
}

//TODO
const DefaultLayout = () => (
    <Container>
        <Switch>
            <Route path='/Account/ResetPassword/:id' component={ResetPasswordForm} />
            <Route exact path='/login' component={LoginForm} />
            <Route exact path='/forgotpassword' component={ForgotPasswordForm} />
            <Route component={NotFound} />
        </Switch>
    </Container>
);



export default observer(App);
