import { createContext, useContext } from "react";
import CommonStore from "./commonStore";
import UserStore from "./userStore";
import ModalStore from './modalStore'
import ApplicationStore from './applicationStore'
import RoleStore from './roleStore'
import CompanyProfileStore from './companyProfileStore'
import LookupStore from './lookupStore'
import AuthStore from './authStore'
import IPAddressStore from "./ipaddressStore";

interface Store {
    commonStore: CommonStore;
    userStore: UserStore;
    modalStore: ModalStore;
    applicationStore: ApplicationStore
    roleStore: RoleStore
    companyProfileStore: CompanyProfileStore,
    lookupStore: LookupStore
    authStore: AuthStore
    ipAddressStore: IPAddressStore
}

export const store: Store = {
    commonStore: new CommonStore(),
    userStore: new UserStore(),
    modalStore: new ModalStore(),
    applicationStore: new ApplicationStore(),
    roleStore: new RoleStore(),
    companyProfileStore: new CompanyProfileStore(),
    lookupStore: new LookupStore(),
    authStore: new AuthStore(),
    ipAddressStore: new IPAddressStore(),
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}