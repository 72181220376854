import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { RefreshToken, ResetPasswordFormValues, User, UserFormValues, UserRole, UserRoleFormValues } from '../models/user';
import { store } from '../stores/store';
import { Application } from '../models/application';
import { SocialMedia } from '../models/socialMedia';
import { Filters } from '../models/filter';
import { Role } from '../models/role';
import { CompanyProfile } from '../models/companyProfile';
import { IPAddress } from '../models/ipAddress';
import axiosService from './axios-service';
import moment from 'moment';

const requests = {
    get: <T>(url: string, config?: AxiosRequestConfig) => axiosService.get<T>(url, config).then(responseBody),
    post: <T>(url: string, body: {}) => axiosService.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axiosService.put<T>(url, body).then(responseBody),
    patch: <T>(url: string, body: {}) => axiosService.patch<T>(url, body).then(responseBody),
    delete: <T>(url: string) => axiosService.delete<T>(url).then(responseBody),
}

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const Account = {
    login: (user: UserFormValues) => requests.post<User>('/authenticate/user', user),
    forgotPassword: (credentials: { username: string }) => requests.post<User>('/system/emails/admin-forgot-password', credentials),
    resetPassword: (credentials: ResetPasswordFormValues) => requests.post<void>('/system/emails/reset-password', credentials),
    current: () => requests.get<User>('/accounts/admin/self'),
    getProfilePhoto: (id: string, config: AxiosRequestConfig) => requests.get<Blob>(`accounts/admin/profile-picture`, config),
    refreshToken: (oldToken: RefreshToken) => requests.post<User>('/authenticate/refresh-token', oldToken),
}

const Applications = {
    list: () => requests.get<Application[]>('/lookups/Applications')
}

const Lookups = {
    socialMedia: () => requests.get<SocialMedia[]>('/lookups/socialmedia')
}


const Roles = {
    filter: (query: Filters) => requests.get<{ total: number, roles: Role[] }>(`/roles?${createUrlParam(query)}`),
    getAll: () => requests.get<{ total: number, roles: Role[] }>('/roles'),
    create: (role: Role) => requests.post('/roles', role),
    update: (role: Role) => requests.put(`/roles/${role.id}`, role),
    delete: (id: string) => requests.delete(`/roles/${id}`),
    details: (id: string) => requests.get<Role>(`/roles/${id}`),
}

const Users = {
    filter: (query: Filters) => requests.get<{ total: number, users: User[] }>(`/users?${createUrlParam(query)}`),
    GetRoles: (id: string) => requests.get<UserRole>(`/users/${id}/roles`),
    AssignRoles: (userRole: UserRoleFormValues) => requests.patch(`/users/roles`, userRole)
}

const CompanyProfiles = {
    update: (companyProfile: CompanyProfile) => requests.put(`/company-profiles`, companyProfile),
    details: () => requests.get<CompanyProfile>(`/company-profiles`),
}

const IPAddresses = {
    getAll: () => requests.get<IPAddress[]>(`/ip-addresses`),
    get: (id: string) => requests.get<{ address: IPAddress }>(`/ip-addresses/${id}`),
    create: (ipaddress: IPAddress) => requests.post<string>('/ip-addresses', ipaddress),
    update: (ipaddress: IPAddress) => requests.put(`/ip-addresses/${ipaddress.id}`, ipaddress),
    delete: (id: string) => requests.delete(`/ip-addresses/${id}`),
}

const createUrlParam = (params: any) => {
    let str = "";

    for (var key in params) {
        if (!Array.isArray(params[key])) {
            if (params[key]) {
                if (str != "") {
                    str += "&";
                }

                if (params[key] instanceof Date) {
                    str += key + "=" + moment(params[key]).format('YYYY-MM-DD')
                }
                else if (key === 'searchKeyword') {
                    str += "search" + "=" + encodeURIComponent(params[key]);
                }
                else if (key === "pageSize") {
                    str += "take" + "=" + encodeURIComponent(params[key]);
                }
                else if (key === "pageNumber") {
                    str += "skip" + "=" + encodeURIComponent(((params[key] - 1) * params["pageSize"]));
                }
                else {
                    str += key + "=" + encodeURIComponent(params[key]);
                }
            }
        }
        else {
            for (let variable of params[key]) {
                if (variable) {
                    if (variable != "") {
                        str += "&";
                    }
                    str += key + "=" + encodeURIComponent(variable);
                }
            }
        }
    }

    return str;
}


const agent = {
    Account,
    Applications,
    Roles,
    Users,
    CompanyProfiles,
    Lookups,
    IPAddresses
}

export default agent;
