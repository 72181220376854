import { Header } from 'semantic-ui-react';
import styled from "styled-components";

interface Props {
    content: string;
}

const HeaderDesign = styled(Header)`margin-bottom: 25px !important; font-size: 18px;`;

export default function StyledHeader(props: Props) {
    return (
        <HeaderDesign>
            {props.content}
        </HeaderDesign>
    )
}