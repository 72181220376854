import * as Permissions from '../../constants/permissions';
import { FaUsers, FaTools } from "react-icons/fa";
import { GoFileSubmodule } from "react-icons/go";
import { RiUserSettingsLine } from "react-icons/ri";

const styles = { fontSize: '1.7rem' };

export const SidebarData = [
    { text: "Application & Modules", path: "/applications", icon: <GoFileSubmodule style={styles} />, permission: Permissions.ApplicationAndModules },
    { text: "Role", path: "/roles", icon: <RiUserSettingsLine style={styles} />, permission: Permissions.Roles },
    { text: "User", path: "/users", icon: <FaUsers style={styles} />, permission: Permissions.UserRoles },
    {
        text: "Settings", icon: <FaTools style={styles} />,
          subNav: [
            {
                text: 'IP Address',
                path: "/settings/ip-addresses",
                permission: Permissions.IpAddress,
              },
        ]
    },
];

